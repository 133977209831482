exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-orthobiologics-sanity-product-brand-slug-current-js": () => import("./../../../src/pages/orthobiologics/{SanityProductBrand.slug__current}.js" /* webpackChunkName: "component---src-pages-orthobiologics-sanity-product-brand-slug-current-js" */),
  "component---src-pages-procedural-solutions-sanity-site-procedure-slug-current-js": () => import("./../../../src/pages/procedural-solutions/{SanitySiteProcedure.slug__current}.js" /* webpackChunkName: "component---src-pages-procedural-solutions-sanity-site-procedure-slug-current-js" */),
  "component---src-pages-product-types-sanity-site-type-slug-current-js": () => import("./../../../src/pages/product-types/{SanitySiteType.slug__current}.js" /* webpackChunkName: "component---src-pages-product-types-sanity-site-type-slug-current-js" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-products-sanity-site-product-slug-current-tsx": () => import("./../../../src/pages/products/{SanitySiteProduct.slug__current}.tsx" /* webpackChunkName: "component---src-pages-products-sanity-site-product-slug-current-tsx" */),
  "component---src-pages-sanity-site-route-slug-current-js": () => import("./../../../src/pages/{SanitySiteRoute.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-site-route-slug-current-js" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-technologies-sanity-site-technology-slug-current-js": () => import("./../../../src/pages/technologies/{SanitySiteTechnology.slug__current}.js" /* webpackChunkName: "component---src-pages-technologies-sanity-site-technology-slug-current-js" */)
}

